<template>
<div class="forgetpaw">
   <img
      src="../../../../assets/pc/images/login/loginLogo.png"
      alt=""
    />
  <!--    右侧头部区域-->
  <div class="login-right-top flex">
    <router-link to="normal">返回登录</router-link>
    <span>重置密码</span>
    
  </div>
  <!--    右侧表单区域-->
  <div class="login-form">
    <el-form ref="resetFormRef"
             :model="resetLoginForm"
             :rules="resetLoginFormRules">
      <el-form-item prop="phone">
        <div>手机号</div>
        <el-input v-model.trim="resetLoginForm.phone"
                  placeholder="请输入手机号码"
                  maxlength="11"
                  @keyup.native="restempty('resetLoginForm','phone')"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <div>短信验证码</div>
        <el-input v-model="resetLoginForm.code"
                  placeholder="请输入5位验证码"
                  maxlength="5"
                  onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"></el-input>
        <el-button plain
                   class="get-verfi"
                   @click="getVerfiCode"
                   v-show="!isPostCode">获取验证码</el-button>
        <el-button plain
                   disabled
                   class="get-verfi"
                   v-show="isPostCode">{{ timer }}</el-button>
      </el-form-item>
      <el-form-item prop="passWord">
        <div>登录密码</div>
        <el-input v-model="resetLoginForm.passWord"
                  placeholder="请输入新密码 (6-20位，须含数字和字母)"
                  auto-complete="new-password"
                  type="password"
                  readonly
                  @focus="onfocus('password')"
                  @blur="onblur('password')"
                  id="password"
                  maxlength="20"
                  @keyup.native="restempty('resetLoginForm','passWord')"></el-input>
      </el-form-item>
      <el-form-item prop="passWordAgain">
        <div>确认密码</div>
        <el-input v-model="resetLoginForm.passWordAgain"
                  :disabled="isNotAgainAble"
                  placeholder="请再次输入新密码"
                  type="password"
                  readonly
                  @focus="onfocus('passwordAgain')"
                  @blur="onblur('passwordAgain')"
                  id="passwordAgain"
                  maxlength="20"
                  @keyup.native="restempty('resetLoginForm','passWordAgain')"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary"
                   class="submit-btn"
                   @click="resetPass">重置密码</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
import { request } from '@/network';
export default {
  name: 'loginForgetPaw',
  data () {
    return {
      resetLoginForm: {
        phone: '',
        code: '',
        passWord: '',
        passWordAgain: ''
      },
      resetLoginFormRules: {
        phone: [
          { required: true, pattern: '^[1][3,4,5,6,7,8,9][0-9]{9}$', message: '请输入正确的11位手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        passWord: [
          { required: true, validator: this.testPassWord, message: '请输入新密码（6-20位，须含数字和字母）', trigger: 'blur' }
        ],
        passWordAgain: [
          { required: true, validator: this.testPassWordAgain, trigger: 'blur' }
        ]
      },
      isPostCode: false,
      timer: 0,
      isNotAgainAble: true
    };
  },
  methods: {
    onfocus(e){
      document.getElementById(e).addEventListener('click', this.handleClick);
      document.getElementById(e).addEventListener('keydown', this.handleKeydown);
      document.getElementById(e).addEventListener('mousedown', this.handleMousedown);
      setTimeout(() => {
        //获取焦点时 同时去除只读，这样可以获取光标，进行输入
        document.getElementById(e).removeAttribute('readonly');
      }, 50)
    },
    handleClick(e) {
      //为什么先失去焦点，在获取焦点，这样子可以避免第二次或更多次连续点击输入框时，出现的用户密 
      // 码清单的框可以快速去除
      if (e.type === 'click') {
        document.getElementById(e.target.id).blur();
        document.getElementById(e.target.id).focus();
      }
    },
    handleKeydown(e) {
      if (e.type === 'keydown') {
        const keyCode = e.keyCode;
        // 和JS不一样，TS中写法如此，不然报类型“HTMLElement”上不存在属性“value”
        const passwordText =  (document.getElementById(e.target.id));
        if (keyCode === 8 || keyCode === 46) {
          //backspace 和delete
          const len = passwordText.value.length;
          if (len === 1) {
            console.log(document.getElementById(e.target.id).value);
            passwordText.value = "";
            return false;
          }
          if (e.target.selectionStart === 0 && e.target.selectionEnd === len) {
            passwordText.value = "";
            return false;
          }
        }
        return true;
      }
    },
    handleMousedown(e) {
      if (e.type === 'mousedown') {
        document.getElementById(e.target.id).blur();
        document.getElementById(e.target.id).focus();
      }
    },
    onblur(e){
      //失去焦点立马更新为只读
      document.getElementById(e).setAttribute("readonly", 'true');
    },
    resetPass () {
      this.$refs.resetFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await request({
            method: 'POST',
            url: '/pcp/modify',
            data: {
              phone: this.resetLoginForm.phone,
              code: this.resetLoginForm.code,
              passWord: this.resetLoginForm.passWord
            }
          });
          if (res.code === 501) return this.$message.error(res.message);
          if (res.code !== 200) return this.$message.error('密码重置失败');
          this.$message.success(res.message);
          this.$router.push('/login');
        }
      });
    },
    getVerfiCode () {
      this.$refs.resetFormRef.validateField('phone', async validMes => {
        if (validMes) return this.$message.error(validMes);
        const { data: res } = await request({
          method: 'GET',
          url: '/pcp/verification',
          params: {
            phone: this.resetLoginForm.phone,
            type: 'reset'
          }
        });
        if (res.code === 501) return this.$message.error(res.message);
        if (res.code !== 200) return this.$message.error('验证码发送失败');
        this.$message.success('验证码已发送，请注意查收');
        this.isPostCode = true;
        this.timer = 60;
        const timerInterval = setInterval(() => {
          this.timer--;
          if (this.timer === 0) {
            this.isPostCode = false;
            clearInterval(timerInterval);
          }
        }, 1000);
      });
    },
    restempty (fval, value) {
      this[fval][value] = this[fval][value].replace(/[, ]/g, '');
    },
    testPassWord (rule, value, callback) {
      const testPaw = /^(?!\D+$)(?![^a-zA-Z]+$)\S{6,20}$/;
      if (!testPaw.test(value)) return callback(new Error('请输入新密码（6-20位，须含数字和字母）'));
      this.isNotAgainAble = false;
      callback();
    },
    testPassWordAgain (rule, value, callback) {
      if (value !== this.resetLoginForm.passWord) return callback(new Error('两次输入的密码不一致，请重新输入'));
      callback();
    }
  }
};
</script>

<style scoped lang="less">
/*总体样式*/
.forgetpaw {
  // height: 426px;
  width: 320px;
  margin-left: 139px;
  padding: 40px 0;

}
/*右侧头部区域*/
.login-right-top {
 margin-top: 40px;
}
.login-right-top span{
 color: rgba(16, 16, 16, 100);
  font-size: 24px;
  flex: 1;
  font-weight: 600;
}
.login-right-top a{
 margin-right:50px;
  font-size: 14px;
  color: #156ED0;
  cursor: pointer;
}
/*右侧表单区域*/
.login-form {
  margin-top: 30px;
  padding-bottom: 30px;
}
.el-input {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}
.el-form-item:nth-child(2) .el-input {
  width: 210px;
  margin-right: 10px;
}
.el-form-item:nth-child(2) .el-button {
  width: 100px;
  height: 50px;
  padding: 12px 0;
  font-size: 14px;
  border: 1px solid #156ED0;
  color: #156ED0;
}
.el-form-item:nth-child(2) .el-button {
  margin-left: 0;
}
.el-form-item:nth-child(2) {
  position: relative;
}
/*由于“获取验证码”按钮在强制设置高度之后样式会错位（在此处就是强行往下走了1.5px），所以得处理*/
.get-verfi {
  position: absolute;
}
.el-form-item:nth-child(n-1) {
  margin-bottom: 20px;
}
.el-form-item:last-child{
  margin-bottom: 0;
}
.login-form /deep/.el-input > input{
  height: 50px;
  padding-left: 12px;
  font-size: 16px;
  color: #8D8D8D;
}
.submit-btn {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  background-color: #156ED0;
}
/deep/ .el-form-item__content {
  line-height: 20px;
}
</style>
